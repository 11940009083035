import axios from "axios";

export default {
    /**
     *
     * @param appuntamentoId
     * @returns rapportino creato da me per l'appuntamento in questione
     */
    async getRapportinoAppuntamento(appuntamentoId: string, userID: string) {
        const data = new FormData();
        data.append("where[]", `rapportini_appuntamento_id = ${appuntamentoId}`);
        data.append("where[]", `rapportini_id IN (SELECT rapportini_id FROM rel_rapportini_users WHERE users_id = ${userID})`);

        const response = await axios.post("rest/v1/search/rapportini", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        return response.data;
    },

    async getRapportiniAppuntamenti(ids: string, userID: string) {
        const data = new FormData();
        //data.append("where[]", `rapportini_appuntamento_id = ${appuntamentoId}`);
        data.append(
            "where[]",
            `rapportini_appuntamento_id IN ${ids} AND rapportini_id IN (SELECT rapportini_id FROM rel_rapportini_users WHERE users_id = '${userID}')`
        );

        const response = await axios.post("rest/v1/search/rapportini", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        return response.data;
    },

    async getAppuntamenti(userID: string, dateStart: string, dateEnd: string) {
        const data = new FormData();
        data.append(
            "where[]",
            `appuntamenti_id IN (SELECT appuntamenti_id FROM rel_appuntamenti_persone WHERE users_id = ${userID}) AND (appuntamenti_giorno >= '${dateStart}' AND appuntamenti_giorno <= '${dateEnd}') AND (appuntamenti_da_confermare IS NULL OR appuntamenti_da_confermare = '' OR appuntamenti_da_confermare = '0')`
        );
        /* data.append("where[]", `appuntamenti_giorno >= '${dateStart}' AND appuntamenti_giorno <= '${dateEnd}'`);
        data.append("where[]", `appuntamenti_da_confermare IS NULL OR appuntamenti_da_confermare = '' OR appuntamenti_da_confermare = '0'`); */
        data.append("orderby", "appuntamenti_giorno");
        data.append("orderdir", "desc");
        data.append("depth", "3");

        const response = await axios.post("rest/v1/search/appuntamenti", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response.data;
    },

    async annullaAppuntamento(id: string, motivazione: string) {
        const data = new FormData();
        data.append("appuntamenti_annullato", "1");
        data.append("appuntamenti_motivazione", `${motivazione}`);

        const response = await axios.post(`rest/v1/edit/appuntamenti/${id}`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response.data;
    },
};
