import axios from "axios";

import { setCustomers } from "./localbase";

export default {
    async getCustomers() {
        const data = new FormData();
        //data.append("limit", "300");
        data.append("offset", "0");
        data.append("where[customers_type]", "1");
        data.append("orderby", "customers_company");
        data.append("orderdir", "asc");

        const response = await axios.post("rest/v1/search/customers", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //qui salvo in indexeddb la collection Clienti nel db Clienti
        // con chiave id cliente e valore il cliente
        /*         response.data.data.forEach(cliente => {
            setCustomers(cliente);
        }); */
        //setCustomers(response.data.data);
        //localStorage.setItem('clienti', JSON.stringify(response.data.data));
        //console.log(response);
        return response.data;
    },

    async getFornitori() {
        const data = new FormData();
        //data.append("limit", "300");
        data.append("offset", "0");
        data.append("where[customers_type]", "2");
        data.append("orderby", "customers_company");
        data.append("orderdir", "asc");

        const response = await axios.post("rest/v1/search/customers", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        return response.data;
    },

    async getContatti() {
        const data = new FormData();
        data.append("where[]", "customers_contacts_customer_id IS NOT NULL");
        data.append("orderby", "customers_company");
        data.append("orderdir", "asc");

        const response = await axios.post("rest/v1/search/customers_contacts", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        return response.data;
    },

    async getCustomersLead() {
        const data = new FormData();
        //data.append("limit", "300");
        data.append("offset", "0");
        data.append("where[customers_type]", "4");
        data.append("orderby", "customers_company");
        data.append("orderdir", "asc");

        const response = await axios.post("rest/v1/search/customers", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });

        return response.data;
    },

    async getSediCliente(clienteId: string) {
        const data = new FormData();
        data.append("where[customers_shipping_address_customer_id]", clienteId);

        const response = await axios.post("rest/v1/search/customers_shipping_address", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response.data;
    },

    async saveCliente(cusotmerData) {
        const response = await axios.post("rest/v1/create/customers", cusotmerData, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });

        //console.log(response);
        return response;
    },

    async getCustomerContacts(customerId) {
        const data = new FormData();
        data.append("where[customers_contacts_customer_id]", customerId);
        data.append("orderby", "customers_contacts_creation_date");
        data.append("orderdir", "ASC");
        data.append("limit", "1");

        const response = await axios.post("rest/v1/search/customers_contacts", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        
        return response;
    },
};
